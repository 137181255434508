import * as React from "react";
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/solid";

export interface IContactCardProps {}

export function ContactCard(props: IContactCardProps) {
  const classes = {
    icon:
      "grid w-12 h-12 text-gray-100 bg-yellow-500 rounded-full place-items-center",
    title: "text-gray-800 font-bold font-raleway",
  };

  return (
    <section className="flex flex-col items-center gap-8 p-16 text-center bg-gray-100">
      <h1 className="text-4xl font-light leading-snug text-yellow-600 font-sacramento">
        Kontakt
      </h1>

      <div className="flex flex-col gap-8">
        <div className="flex gap-4">
          <div className={classes.icon}>
            <PhoneIcon className="w-5 h-5 " />
          </div>
          <div className="flex flex-col items-start">
            <h2 className={classes.title}>Pokličite nas</h2>
            <a href="tel:+38641343510" className="text-xl font-raleway">
              +386 41 343 510
            </a>

            <a href="tel:025252020" className="text-xl font-raleway">
              02 5252 020
            </a>
          </div>
        </div>

        <div className="flex gap-4">
          <div className={classes.icon}>
            <MailIcon className="w-5 h-5 " />
          </div>
          <div className="flex flex-col items-start">
            <h2 className={classes.title}>Pišite nam</h2>
            <a
              href="mailto:gostilna.spiric@gmail.com"
              className="text-xl font-raleway"
            >
              gostilna.spiric@gmail.com
            </a>
          </div>
        </div>

        <a
          href="https://goo.gl/maps/MAoqbGJP4J4ZSWay6"
          className="flex gap-4 "
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classes.icon}>
            <LocationMarkerIcon className="w-5 h-5 " />
          </div>
          <div className="flex flex-col items-start">
            <h2 className={classes.title}>Obiščite nas</h2>
            <span
              // href="https://goo.gl/maps/MAoqbGJP4J4ZSWay6"
              className="text-xl text-left font-raleway"
            >
              Branko Spirić s.p. <br />
              Zadružna ulica 2, Černelavci <br />
              9000 Murska Sobota
            </span>
          </div>
        </a>
      </div>
    </section>
  );
}
