import React from "react";
import { Helmet } from "react-helmet";
import { ContactCard } from "../components/features/contact/contact-card";

/* eslint-disable-next-line */
export interface KontaktProps {}

const isClient = typeof window !== "undefined";

export function Kontakt(props: KontaktProps) {
  return (
    // <Layout>
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Kontakt | Gostilna Spirić</title>
        <link rel="canonical" href="https://www.gostilna-spiric.si/kontakt" />
        <meta
          name="description"
          content="Kontaktne informacije Gostilne Spirić"
        />

        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": ["Restaurant", "FoodService"],
          "url": "https://www.gostilna-spiric.si",
          "name": "Gostilna Spirić",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+38641343510",
            "contactType": "Customer Support"
          },
          "openingHours":["Mo-Fr 08:00-22:00", "Sa 08:00-23:59", "Su 08:00-22:00"],
          "currenciesAccepted": "EUR",
          "paymentAccepted":"Cash, Credit Card",
        }
      `}
        </script>
      </Helmet>

      <article className="grid mt-8 sm:px-4 place-items-center">
        <section className="container grid grid-rows-2 overflow-hidden rounded sm:grid-rows-1 sm:grid-cols-2 ">
          {/*  */}
          <ContactCard />
          <div className="bg-gray-200 ">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10951.664024388467!2d16.1367642!3d46.6679194!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xccef5e3a68b60136!2sGostilna%20Spiri%C4%87!5e0!3m2!1ssl!2ssi!4v1619275968859!5m2!1ssl!2ssi"
              width="600"
              height="450"
              className="w-full h-full border-0"
              loading="lazy"
            ></iframe>
          </div>
        </section>

        {/* End: Map card */}
      </article>
    </React.Fragment>
    // </Layout>
  );
}

export default Kontakt;
